import {action, computed, observable} from "mobx";
import {EntityListData} from "../../../data/entityListData";
import {PrinteraTask} from "../../../cuba/entities/printers_PrinteraTask";
import {ConditionsGroup, EntityFilter, SerializedEntity} from "@cuba-platform/rest";
import {Role, TaskState} from "../../../cuba/enums";
import {mainStore} from "../../../index";
import {filterTasks, TaskListFilter} from "./TaskListFilter";
import {tasksComparator} from "../tasksCommon";

export class TaskListStore {
  static readonly NAME = 'taskListStore';

  private static readonly LOAD_LIMIT = 10;

  @observable filteredTasks: SerializedEntity<PrinteraTask>[] = [];
  tasks: SerializedEntity<PrinteraTask>[] = [];
  @observable totalCount = 0;
  @observable offset = 0;
  filter?: TaskListFilter;
  @observable loading = true;
  @observable.ref private tasksData = new EntityListData<PrinteraTask>(PrinteraTask.NAME);
  @observable hasMore = true;

  defaultView = 'printeraTask-mobile-list';
  defaultSort = '+deviceAssignment.location.name';

  doReloadTasks() {
    this.loading = true;
    const existingsFilter = this.getFilter();
    let filter = this.userFilter;
    if (!filter) return;

    filter = {...filter};

    filter.conditions = [...existingsFilter.conditionsREST, ...filter.conditions];
    this.tasksData.filter = filter;

    this.tasksData.view = this.defaultView;
    this.tasksData.sort = this.defaultSort;

    this.load()
      .then((result: {entities?: SerializedEntity<PrinteraTask>[], totalCount?: number}) => {
        this.tasks = result.entities?.slice().sort(tasksComparator) || [];
        this.totalCount = result.totalCount || 0;
        this.filteredTasks = filterTasks(this.tasks, this.getFilter());
        this.loading = false;
        this.hasMore = this.tasks.length === TaskListStore.LOAD_LIMIT + this.offset;
      })
      .catch(() => this.loading = false)
  }

  clean = () => {
    this.filter = undefined;
    this.tasks = [];
    this.filteredTasks = [];
  };

  @computed get userFilter(): EntityFilter | undefined {
    switch (mainStore.role) {
      case Role.FULL:
        if (!mainStore.userInfo) {
          return undefined;
        }
        const filter: EntityFilter = {
          conditions: []
        };
        const completedTaskCG: ConditionsGroup = {
          group: 'AND',
          conditions: [
            {
              property: 'lastPerformer.id',
              operator: '=',
              value: mainStore.userInfo.id
            },
            {
              property: 'completed',
              operator: '=',
              value: 'true'
            }]
        };

        const taskLoadCG: ConditionsGroup = {
          group: 'OR',
          conditions: [{
            property: 'assignedToUser.id',
            operator: '=',
            value: mainStore.userInfo.id
          },
            completedTaskCG as any]
        };
        filter.conditions.push(taskLoadCG)
        return filter;
      case Role.CLIENT:
        if (!mainStore.userInfo) {
          return undefined;
        }
        return {
          conditions: [{
            property: 'initiator.id',
            operator: '=',
            value: mainStore.userInfo.id
          }]
        };
      case Role.CLIENT_ADMIN:
        const {client} = mainStore;
        if (!client) return undefined;
        return {
          conditions: [{
            property: 'client.id',
            operator: '=',
            value: client.id
          }, {
            property: 'printeraTaskType.customerVisibility',
            operator: '=',
            value: 'true'
          }]
        };
      default:
        throw new Error('No role set');
    }
  }

  private load() {
    return this.tasksData.load({limit: TaskListStore.LOAD_LIMIT + this.offset}).then(() => {
      const result = {
        entities: this.tasksData.entities? this.tasksData.entities: [],
        totalCount: this.tasksData.totalCount
      };
      return Promise.resolve(result);
    });
  }

  @action loadMore = () => {
    this.offset += TaskListStore.LOAD_LIMIT;
    this.doReloadTasks();
  }

  @computed get taskCount() {
    return this.totalCount;
  }

  @action filterChange = (reset?: boolean) => {
    if (reset === true) this.filter = new TaskListFilter(mainStore.userId);
    this.filteredTasks = filterTasks(this.tasks, this.getFilter());
    this.doReloadTasks();
  };

  getFilter = () => {
    this.filter = this.filter ? this.filter : this.defaultFilter();
    return this.filter;
  };

  defaultFilter() {
    const filter = new TaskListFilter(mainStore.userId);

    if (mainStore.role === Role.CLIENT_ADMIN) {
      filter.myTasksOnly = true;
      filter.taskState = TaskState.ACTIVE;
    }

    if (mainStore.role === Role.FULL) {
      filter.taskState = TaskState.ACTIVE;
    }
    return filter;
  };

}

export interface TaskListStoreInjected {
  taskListStore: TaskListStore
}


