import React, {Component, PropsWithChildren, ReactNode} from "react";
import {IonBadge, IonCard, IonCardContent} from "@ionic/react";
import {PrinteraTask} from "../../../cuba/entities/printers_PrinteraTask";
import {taskPriorityCaption, taskStateColor, TaskStateColors} from "../tasksCommon";
import {formatServerDateTime} from "../../../util/stringUtils";
import {deviceCaption} from "../../devices/devicesCommon";
import {Role} from "../../../cuba/enums";
import {DeviceAssignment} from "../../../cuba/entities/printers_DeviceAssignment";
import {SerializedEntity} from "@cuba-platform/rest";
import {PrinteraTaskPriority} from "../../../cuba/enums/enums";

type Props = {
  task: PrinteraTask,
  //todo inject from main store
  role: Role
}

const priorityColor: { [key in PrinteraTaskPriority]: string } = {
  [PrinteraTaskPriority.HIGH]: 'danger',
  [PrinteraTaskPriority.LOW]: 'medium',
  [PrinteraTaskPriority.NORMAL]: 'light',
};

export class TaskCard extends Component<Props> {

  render() {

    const {
      description,
      header,
      number,
      deviceAssignment,
      priority,
      dueDate,
      office,
      client,
      completed,
      takenToWork,
      dueDateSLA,
      dateOfCompletion,
      state,
    } = this.props.task;


    const stateColor: TaskStateColors | undefined = taskStateColor(takenToWork, dueDateSLA, dueDate, dateOfCompletion);
    const headerStyle = {
      ...{paddingBottom: '6px', fontSize: '20px'},
      ...(stateColor && {color: stateColor}),
      ...(completed && {textDecoration: 'line-through'})};

    return (
      <IonCard>
        <IonCardContent>
          <div style={headerStyle}>
            {number} - {header}
          </div>
          <TaskCardItem>
            {renderPriority(priority)}
          </TaskCardItem>

          <TaskCardItem>
            {client && client.clientName}, {office && office.officeName}
          </TaskCardItem>
          <TaskCardItem>
            {renderDevice(deviceAssignment, this.props.role)}
          </TaskCardItem>
          {dueDate &&
          <TaskCardItem>
            Выполнить до: {formatServerDateTime(dueDate)}
          </TaskCardItem>
          }
          <TaskCardItem>
            {renderDescription(description)}
          </TaskCardItem>
          {state && <TaskCardItem>
            Статус: {state.name}
          </TaskCardItem>}
        </IonCardContent>
      </IonCard>
    )
  }
}

function TaskCardItem(props: PropsWithChildren<{}>) {
  return (
    <div style={{padding: '3px 0'}}>
      {props.children}
    </div>
  );
}

function renderDevice(deviceAssignment: DeviceAssignment | null | undefined, role: Role): string {
  if (deviceAssignment == null) {
    return 'Устройство не указано'
  }
  return deviceCaption(deviceAssignment as SerializedEntity<DeviceAssignment>, role);
}

function renderPriority(priority: PrinteraTaskPriority | null | undefined): ReactNode {
  if (priority == null) {
    return null;
  }
  return (
    <IonBadge color={priorityColor[priority]}>{taskPriorityCaption(priority)} приоритет</IonBadge>
  )
}

function renderDescription(description?: string | null): ReactNode {
  if (description == null) {
    return null;
  }
  return (
    <span style={{textOverflow: 'ellipsis'}}>
      {description}
    </span>
  )
}
