import { StandardEntity } from "./base/sys$StandardEntity";
import { PrinteraTaskType } from "./printers_PrinteraTaskType";
import {
  PrinteraTaskPriority,
  PrinteraTaskStatus,
  CreationType,
  CreationSource
} from "../enums/enums";
import { Client } from "./printers_Client";
import { Office } from "./printers_Office";
import { DeviceAssignment } from "./printers_DeviceAssignment";
import { PrinteraUser } from "./printers_PrinteraUser";
import { PrinteraTaskLogItem } from "./printers_PrinteraTaskLogItem";
import { PrinteraTaskItem } from "./printers_PrinteraTaskItem";
import { UserGroup } from "./base/bproc_UserGroup";
import { PrinteraTaskUploadedFile } from "./printers_PrinteraTaskUploadedFile";
import { Poller } from "./printers_Poller";
export class PrinteraTask extends StandardEntity {
  static NAME = "printers_PrinteraTask";
  number?: any | null;
  printeraTaskType?: PrinteraTaskType | null;
  header?: string | null;
  description?: string | null;
  priority?: PrinteraTaskPriority | null;
  client?: Client | null;
  office?: Office | null;
  deviceAssignment?: DeviceAssignment | null;
  creationDate?: any | null;
  periodOfExecution?: number | null;
  dateOfCompletion?: any | null;
  dueDate?: any | null;
  dueDateSLA?: any | null;
  takenToWork?: any | null;
  initiator?: PrinteraUser | null;
  logItems?: PrinteraTaskLogItem[] | null;
  completed?: boolean | null;
  taskItems?: PrinteraTaskItem[] | null;
  assignedToUser?: PrinteraUser | null;
  assignedToGroup?: UserGroup[] | null;
  status?: PrinteraTaskStatus | null;
  state?: {
    id: string;
    name: string;
  };
  taskId?: any | null;
  executionTaskId?: any | null;
  printeraTaskUploadedFiles?: PrinteraTaskUploadedFile[] | null;
  processInstanceDataId?: string | null;
  lastTechnician?: PrinteraUser | null;
  creationType?: CreationType | null;
  creationSource?: CreationSource | null;
  bw?: any | null;
  color?: any | null;
  scan?: any | null;
  parentTask?: PrinteraTask | null;
  subTasks?: PrinteraTask[] | null;
  poller?: Poller | null;
}
export type PrinteraTaskViewName =
  | "_minimal"
  | "_local"
  | "_base"
  | "printeraTask-view"
  | "printeraTask-mobile-view"
  | "printeraTask-mobile-list"
  | "printeraTask-auto-view"
  | "printeraTask-light-only-poller";
export type PrinteraTaskView<
  V extends PrinteraTaskViewName
> = V extends "_minimal"
  ? Pick<PrinteraTask, "id" | "header">
  : V extends "_local"
  ? Pick<
      PrinteraTask,
      | "id"
      | "number"
      | "header"
      | "description"
      | "priority"
      | "creationDate"
      | "periodOfExecution"
      | "dateOfCompletion"
      | "dueDate"
      | "dueDateSLA"
      | "takenToWork"
      | "completed"
      | "status"
      | "taskId"
      | "executionTaskId"
      | "processInstanceDataId"
      | "creationType"
      | "creationSource"
      | "bw"
      | "color"
      | "scan"
    >
  : V extends "_base"
  ? Pick<
      PrinteraTask,
      | "id"
      | "header"
      | "number"
      | "description"
      | "priority"
      | "creationDate"
      | "periodOfExecution"
      | "dateOfCompletion"
      | "dueDate"
      | "dueDateSLA"
      | "takenToWork"
      | "completed"
      | "status"
      | "taskId"
      | "executionTaskId"
      | "processInstanceDataId"
      | "creationType"
      | "creationSource"
      | "bw"
      | "color"
      | "scan"
    >
  : V extends "printeraTask-view"
  ? Pick<
      PrinteraTask,
      | "id"
      | "number"
      | "header"
      | "description"
      | "priority"
      | "creationDate"
      | "periodOfExecution"
      | "dateOfCompletion"
      | "dueDate"
      | "dueDateSLA"
      | "takenToWork"
      | "completed"
      | "status"
      | "taskId"
      | "executionTaskId"
      | "processInstanceDataId"
      | "creationType"
      | "creationSource"
      | "bw"
      | "color"
      | "scan"
      | "printeraTaskType"
      | "parentTask"
      | "client"
      | "office"
      | "deviceAssignment"
      | "initiator"
      | "taskItems"
      | "subTasks"
      | "logItems"
      | "assignedToUser"
      | "assignedToGroup"
      | "printeraTaskUploadedFiles"
      | "lastTechnician"
      | "poller"
    >
  : V extends "printeraTask-mobile-view"
  ? Pick<
      PrinteraTask,
      | "id"
      | "number"
      | "header"
      | "description"
      | "priority"
      | "creationDate"
      | "periodOfExecution"
      | "dateOfCompletion"
      | "dueDate"
      | "dueDateSLA"
      | "takenToWork"
      | "completed"
      | "status"
      | "taskId"
      | "executionTaskId"
      | "processInstanceDataId"
      | "creationType"
      | "creationSource"
      | "bw"
      | "color"
      | "scan"
      | "printeraTaskType"
      | "client"
      | "office"
      | "deviceAssignment"
      | "initiator"
      | "logItems"
      | "taskItems"
      | "assignedToUser"
      | "assignedToGroup"
      | "printeraTaskUploadedFiles"
      | "lastTechnician"
      | "parentTask"
      | "subTasks"
      | "poller"
    >
  : V extends "printeraTask-mobile-list"
  ? Pick<
      PrinteraTask,
      | "id"
      | "header"
      | "number"
      | "description"
      | "priority"
      | "creationDate"
      | "periodOfExecution"
      | "dateOfCompletion"
      | "dueDate"
      | "dueDateSLA"
      | "takenToWork"
      | "completed"
      | "status"
      | "taskId"
      | "executionTaskId"
      | "processInstanceDataId"
      | "creationType"
      | "creationSource"
      | "bw"
      | "color"
      | "scan"
      | "printeraTaskType"
      | "client"
      | "office"
      | "deviceAssignment"
      | "initiator"
      | "poller"
    >
  : V extends "printeraTask-auto-view"
  ? Pick<
      PrinteraTask,
      | "id"
      | "number"
      | "header"
      | "description"
      | "priority"
      | "creationDate"
      | "periodOfExecution"
      | "dateOfCompletion"
      | "dueDate"
      | "dueDateSLA"
      | "takenToWork"
      | "completed"
      | "status"
      | "taskId"
      | "executionTaskId"
      | "processInstanceDataId"
      | "creationType"
      | "creationSource"
      | "bw"
      | "color"
      | "scan"
      | "deviceAssignment"
    >
  : V extends "printeraTask-light-only-poller"
  ? Pick<
      PrinteraTask,
      | "id"
      | "number"
      | "header"
      | "description"
      | "priority"
      | "creationDate"
      | "periodOfExecution"
      | "dateOfCompletion"
      | "dueDate"
      | "dueDateSLA"
      | "takenToWork"
      | "completed"
      | "status"
      | "taskId"
      | "executionTaskId"
      | "processInstanceDataId"
      | "creationType"
      | "creationSource"
      | "bw"
      | "color"
      | "scan"
      | "poller"
    >
  : never;
